import { Slot } from '@radix-ui/react-slot';
import { headingVariants, textVariants } from '~/components/ui/Typography';
import { cn } from '~/utils/cn';

export function Section(props: React.ComponentProps<'section'>) {
  return <section {...props} />;
}

export function SectionContent(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn('mx-auto max-w-screen-2xl px-4 py-16 md:px-20 md:py-24', props.className)}
    />
  );
}

export function SectionHeader(props: React.ComponentProps<'header'>) {
  return (
    <header
      {...props}
      className={cn('mb-10 flex flex-col justify-center gap-y-6 md:mb-14', props.className)}
    />
  );
}

export function SectionTitle({
  asChild,
  ...props
}: React.ComponentProps<'h3'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'h2';
  return <Comp {...props} className={cn(headingVariants({ size: 'xxl' }), props.className)} />;
}

export function SectionDescription(props: React.ComponentProps<'p'>) {
  return (
    <p
      {...props}
      className={cn(
        textVariants({ size: 'xl' }),
        'font-medium text-subdued-foreground',
        props.className,
      )}
    />
  );
}
